import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
  path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'members/profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  { path: 'members/tickets',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  { path: 'members/tickets-config',
    loadChildren: () => import('./help-config/help-config.module').then( m => m.HelpConfigPageModule)
  },
  { path: 'members/payment-config',
    loadChildren: () => import('./payment-config/payment-config.module').then( m => m.PaymentConfigPageModule)
  },
  { path: 'members/modal-predefined',
    loadChildren: () => import('./modalPredefined/modalPredefined.module').then( m => m.ModalPredefinedPageModule)
  },
  {
    path: 'members/tickets/tickets-add',
    loadChildren: () => import('./help-add/help-add.module').then( m => m.HelpAddPageModule)
  },
  {
    path: 'members/chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'members/chat',
    loadChildren: () => import('./user-approve/user-approve.module').then( m => m.UserApprovePageModule)
  },{
    path: 'members/modal-attach',
    loadChildren: () => import('./modalAttach/modalAttach.module').then( m => m.ModalAttachPageModule)
  }  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
